import { SxStyleProp } from "theme-ui";

export const wrapperStyles: SxStyleProp = {
  display: "flex",
  flexDirection: "column",
  p: 0,
  mb: 4
};

export const labelStyles: SxStyleProp = {
  px: 4,
  pb: 2
};
